<template>
  <page-view class="register" :up-class="'register'">
    <div class="tip-box">
      <div class="tip-content">
        <div class="left">{{ hello }}<span class="tip">请注册</span></div>
        <div class="right">
          欢迎来到百川商展
          <span class="tip">客服电话 : 0755-23736340</span>
          <span class="tip">客服邮箱: 2169047119@qq.com</span>
        </div>
      </div>
    </div>
    <div class="logo-box">
      <div class="logo-content">
        <img src="../assets/img/logo.png" @click="onLogo" alt="" />
      </div>
    </div>
    <div class="form-box">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" size="medium">
        <el-form-item label="企业类型:" prop="bc_company_type">
          <el-select v-model="ruleForm.bc_company_type" placeholder="请选择企业类型" style="width: 100%">
            <el-option label="品牌方" value="1"></el-option>
            <el-option label="设计公司" value="2"></el-option>
            <el-option label="深化图纸" value="3"></el-option>
            <el-option label="道具公司" value="4"></el-option>
            <el-option label="材料公司" value="6"></el-option>
            <el-option label="装修公司" value="5"></el-option>
            <el-option label="监理公司" value="8"></el-option>
            <el-option label="物流公司" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司全称:" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="公司地址:" prop="address" class="address">
          <!-- TODO 嵌套el-form-item -->
          <div class="province-select">
            <el-select v-model="ruleForm.province" placeholder="省" @change="changeProvince">
              <el-option :label="item.name" :value="item.id" v-for="item in provinceList" :key="item.id"></el-option>
            </el-select>
            <span class="address-line">-</span>
            <el-select v-model="ruleForm.city" placeholder="市" @change="changeCity">
              <el-option :label="item.name" :value="item.id" v-for="item in cityList" :key="item.id"></el-option>
            </el-select>
            <span class="address-line">-</span>
            <el-select v-model="ruleForm.district" placeholder="区">
              <el-option :label="item.name" :value="item.id" v-for="item in areaList" :key="item.id"></el-option>
            </el-select>
          </div>
          <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <!-- <el-form-item label="公司昵称:" prop="nickName">
          <el-input v-model="ruleForm.nickName"></el-input>
        </el-form-item> -->
        <el-form-item label="公司规模:" prop="bc_company_size">
          <el-radio-group v-model="ruleForm.bc_company_size">
            <el-radio label="1~10人"></el-radio>
            <el-radio label="11~100人"></el-radio>
            <el-radio label="101~500人"></el-radio>
            <el-radio label="500以上"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="统一信用代码:" prop="tax_reg_num">
          <el-input v-model="ruleForm.tax_reg_num"></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="linkName">
          <el-input v-model="ruleForm.linkName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="开户银行:" prop="bank">
          <el-input v-model="ruleForm.bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号:" prop="bankNum">
          <el-input v-model="ruleForm.bankNum"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="登录密码:" prop="pwd">
          <el-input v-model="ruleForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="sPwd">
          <el-input v-model="ruleForm.sPwd"></el-input>
        </el-form-item> -->
        <el-form-item label="手机号码:" prop="mobile" class="send-item">
          <el-input v-model="ruleForm.mobile">
            <template slot="append" class="lllww">
              <div class="send-code" @click="sendCode" v-if="auth_time <= 0">
                发送验证码
              </div>
              <div class="send-code re" v-else>
                {{ `重发验证码(${auth_time}s)` }}
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="telCode">
          <el-input v-model="ruleForm.telCode" maxlength="6" show-word-limit></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="营业执照:"
          prop="bc_business_license_picture"
          class="upload-item"
        >
          <el-upload
            class="avatar-uploader"
            action="https://server.bcceglobal.com/process/attachment/upload"
            :data="{ type: 'goods/2/20230113/goodFile' }"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="ruleForm.bc_business_license_picture"
              :src="ruleForm.bc_business_license_picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="upload-logo">
            <label class="upload-logo-label">公司logo:</label>
            <el-upload
              class="avatar-uploader"
              action="https://server.bcceglobal.com/process/attachment/upload"
              :data="{ type: 'goods/2/20230113/goodFile' }"
              :show-file-list="false"
              :on-success="handleLogo"
            >
              <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item> -->
        <div class="btn">
          <div class="btn-tip" :class="{ active: btnTip }">
            <el-checkbox v-model="checked">我已同意并阅读</el-checkbox>
          </div>
          <div class="register-btn" @click="submitForm">同意并注册</div>
        </div>
      </el-form>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "Register",
  data() {
    return {
      checked: false,
      btnTip: false,
      auth_time: 0,
      ruleForm: {
        bc_company_type: "1",
        name: "",
        province: "",
        city: "",
        district: "",
        address: "",
        bc_company_size: "101~500人",
        tax_reg_num: "",
        linkName: "",
        mobile: "",
        bc_business_license_picture: "",
        logo: "",
        telCode: "",
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      rules: {
        bc_company_type: [
          { required: true, message: "请输入企业类型", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入公司全称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        nickName: [
          { required: true, message: "请输入公司昵称", trigger: "blur" },
        ],
        tax_reg_num: [
          { required: true, message: "请输入税务登记号", trigger: "blur" },
        ],
        linkName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        bank: [{ required: true, message: "请输入开户银行", trigger: "blur" }],
        bankNum: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        telCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    hello() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let text = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        text = `早上好！`;
      } else if (hours > 10 && hours <= 14) {
        text = `中午好！`;
      } else if (hours > 14 && hours <= 18) {
        text = `下午好！`;
      } else if (hours > 18 && hours <= 24) {
        text = `晚上好！`;
      }
      // 返回当前时间段对应的状态
      return text;
    },
  },
  created() {
    this.getAddress(2);
  },
  methods: {
    ...mapActions(["setNavIndex"]),
    //点击logo跳转首页
    onLogo() {
      this.$router.push({ name: "Index" });
      this.setNavIndex("a");
    },
    //发送手机验证码
    async sendCode() {
      let flag = true;
      //确认有手机号及是否已发送
      this.$refs.ruleForm.validateField("mobile", (field) => {
        if (field != "" && this.auth_time >= 0) {
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }

      axios({
        url: "https://server.bcceglobal.com/mobile/sdk/send",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          phone: this.ruleForm.mobile,
          flag: 1,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("验证码已发送");
            //设置倒计时秒
            this.auth_time = 120;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
          } else if (res.data.code == 10091) {
            this.$message.warning(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取省市区接口
    async getAddress(type, parent_id) {
      let result = await this.$api.getAddress({
        type,
        parent_id,
      });
      if (type == 2) {
        this.provinceList = result;
      } else if (type == 3) {
        this.cityList = result;
        this.ruleForm.city = "";
        this.ruleForm.district = "";
      } else if (type == 4) {
        this.areaList = result;
        this.ruleForm.district = "";
      }
    },
    changeProvince(val) {
      this.getAddress(3, val);
    },
    changeCity(val) {
      this.getAddress(4, val);
    },
    //上传营业执照
    handleAvatarSuccess(res, file) {
      this.ruleForm.bc_business_license_picture = URL.createObjectURL(file.raw);
    },
    //上传logo
    handleLogo(res, file) {
      this.ruleForm.logo = URL.createObjectURL(file.raw);
    },
    //提交表单
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          //是否勾选协议
          if (this.checked) {
            //验证码是否正确
            axios({
              url: "https://server.bcceglobal.com/mobile/sdk/verification",
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: {
                phone: this.ruleForm.mobile,
                code: this.ruleForm.telCode,
              },
            })
              .then(async (res) => {
                if (res.data.code == 200) {
                  let form = this.ruleForm;
                  //添加地址名称
                  form.provinceName = this.provinceList.find(
                    (i) => i.id == this.ruleForm.province
                  ).name;
                  form.cityName = this.cityList.find(
                    (i) => i.id == this.ruleForm.city
                  ).name;
                  form.districtName = this.areaList.find(
                    (i) => i.id == this.ruleForm.district
                  ).name;
                  //提交注册表单
                  let result = await this.$api.register(form);
                  if (result.code == 200) {
                    //注册完跳转首页
                    this.$alert("注册成功，请等待平台审核！", "", {
                      showClose: false,
                      confirmButtonText: "确定",
                      callback: (action) => {
                        this.onLogo();
                      },
                    });
                  } else {
                    this.$message.error(result.message);
                  }
                } else {
                  //验证码不正确及其他情况
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.btnTip = true;
            setTimeout(() => {
              this.btnTip = false;
            }, 400);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.register {
  .tip-box {
    padding: 10px 0;
    background-color: #f5f5f5;
    font-size: 12px;

    .tip-content {
      width: $containerWidth;
      margin: 0 auto;
      @include flexbox(row, space-between, center);

      .tip {
        color: #e71720;
      }

      .right {
        .tip {
          margin-left: 20px;
        }
      }
    }
  }

  .logo-box {
    border-bottom: 1px solid #999999;
    padding: 30px 0;

    .logo-content {
      width: $containerWidth;
      margin: 0 auto;

      >img {
        height: 59px;
        cursor: pointer;
      }
    }
  }

  .form-box {
    width: 710px;
    margin: 0 auto;
    padding: 30px 0;
    @include flexbox(row, center);

    :deep(.el-form) {
      .address {
        .province-select {
          margin-bottom: 10px;
          @include flexbox(row);

          .address-line {
            margin: 0 2px;
            color: #bbbbbb;
          }
        }
      }

      .el-form-item__content {
        width: 430px;
      }

      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }

      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .btn {
        .btn-tip {
          padding-left: 100px;
          margin-bottom: 20px;

          &.active {
            animation: wobble 0.2s ease-in-out 0s 2;

            @keyframes wobble {
              0% {
                transform: translateX(0px);
              }

              25% {
                transform: translateX(-50px);
              }

              50% {
                transform: translateX(0px);
              }

              75% {
                transform: translateX(50px);
              }

              100% {
                transform: translateX(0px);
              }
            }
          }
        }

        .register-btn {
          margin-left: 150px;
          color: #ffffff;
          font-size: 18px;
          width: 200px;
          text-align: center;
          padding: 14px 0;
          background-color: $primaryColor;
          cursor: pointer;
        }
      }

      .send-item {
        .el-input-group {
          @include flexbox(row);

          .el-input__inner {
            flex: 1;
          }

          .el-input-group__append {
            background-color: transparent;
            line-height: inherit;
            padding: 0 0px;
            width: auto;

            .send-code {
              padding: 0 10px;
              height: 100%;
              line-height: 1;
              cursor: pointer;
              @include flexbox(row, center, center);

              &.re {
                background-color: #f4f5f6;
                font-size: 12px;
                color: #a5a5a5;
                cursor: no-drop;
              }
            }
          }
        }
      }

      .upload-item {
        .el-form-item__content {
          @include flexbox(row, space-between);

          &::before,
          &::after {
            content: none;
          }

          .upload-logo {
            font-size: 14px;
            color: #606266;
            @include flexbox(row);

            .upload-logo-label {
              padding-right: 12px;
            }
          }
        }
      }

      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 138px;
        height: 138px;
        line-height: 138px;
        text-align: center;
      }

      .avatar {
        width: 138px;
        height: 138px;
        display: block;
      }
    }
  }
}
</style>